import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class ScreensizeService {
  private readonly _isDesktop$ = new BehaviorSubject<boolean>(window.innerWidth < 768 ? false : true);

  constructor() {}

  onResize(size: number): void {
    if (size < 768) {
      this._isDesktop$.next(false);
    } else {
      this._isDesktop$.next(true);
    }
  }

  isDesktopView(): Observable<boolean> {
    return this._isDesktop$.asObservable().pipe(distinctUntilChanged());
  }
}
